import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {DUMMY_ATTENDEES} from '@posh/model-types'
import {DEFAULT_ACCENT_COLOR, EOptimizelyEvents} from '@posh/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import {trackOptimizelyEvent} from 'apis/OptimizelyHandler'
import {useConfirmPendingEvent} from 'apis/PendingEvent/useConfirmPendingEvent'
import {useGetPendingEvent} from 'apis/PendingEvent/useGetPendingEvent'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import {useToggle} from 'hooks/useToggle'
import {ActionRowSaveButtonProps} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/ActionRow'
import {CreateEventActionRow} from 'pages/EventManagementPages/Visuals/Upgrade-dev/components/page-sections/CreateEventActionRow'

import {useCreatePendingEvent} from '../../apis/PendingEvent/useCreatePendingEvent'
import {transformGetPendingEventOutputToUpdatePendingEventInput} from '../../apis/PendingEvent/useUpdatePendingEvent'
import {
  EventVisualsForm,
  getCreateEventMixpanelParams,
  getEventVisualsAttributes,
} from '../../components/PageComponents/EventVisuals/types/eventVisualsForm'
import {EventVisualsContextProvider} from '../EventManagementPages/Visuals/Upgrade-dev/components/context/EventVisualsContext'
import {VisualEditorWrapper} from '../EventManagementPages/Visuals/Upgrade-dev/components/page/VisualEditorWrapper/index'
import {EventVisualsActivitySection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/ActivitySection/EventVisualsActivitySection'
import {
  determineIsRSVPEvent,
  EventVisualsAdmissionSection,
} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/AdmissionSection/EventVisualsAdmissionSection'
import {EventVisualsBackgroundImage} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/BackgroundImage'
import {CreateEventOrganizerDetails} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/CreateEventOrganizerDetails'
import {EventVisualsCustomSections} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/CustomSections'
import {EventDetailsFormSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventDetails'
import {EventFlyerColorSongSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventFlyer/EventFlyerColorSongSection'
import {EventSettingsSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/EventSettings'
import {EventVisualsGuestlistSection} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/GuestListSection/EventVisualsGuestlistSection'
import {EventVisuals} from '../EventManagementPages/Visuals/Upgrade-dev/components/page-sections/Layout'
import {useAutosave} from './api/useAutosavePendingEvent'
import {useUpdatePendingEventForCreateEvent} from './api/useUpdatePendingEventForCreateEvent'
import FreelancerBooking from './components/FreelancerBooking'
import {CreateEventOnboardingOverlay} from './components/OnboardingOverlay'
import {useGetCreateEventProgress} from './components/ProgressBar/useGetProgress'
import {useDeterminePendingEventDefaultAttributes} from './hooks/useDeterminePendingEventDefaultAttributes'
import {LaunchEventModal} from './LaunchEventModal'
import {useCreateEventParams} from './useCreateEventParams'
import {useNavigateToCreateEvent} from './useNavigateToCreateEvent'

import styles from './styles.module.scss'

export const CreateEvent = () => {
  const [isShowingAdvancedSettings, toggleAdvancedSettings] = useToggle(false)
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false)

  const showAdvancedSettingsText = isShowingAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'

  // Optimizely Default Attributes for Pending Event
  const {defaultAttributes} = useDeterminePendingEventDefaultAttributes()

  const {currentUser} = useSessionContext()
  const {trackEvent} = useMixpanel()
  const {showToast} = useToast()
  const {navigateToCreateEvent} = useNavigateToCreateEvent()
  const navigate = useNavigate()
  const {updatePendingEvent, isUpdatingPendingEvent} = useUpdatePendingEventForCreateEvent()
  const {groupId, pendingEventId} = useCreateEventParams()
  const {mutate: createPendingEvent, isLoading: isCreatingPendingEvent} = useCreatePendingEvent({
    onSuccess: data => {
      navigateToCreateEvent({pendingEventId: data.pendingEventId, groupId})
    },
    onError: error => {
      navigateToCreateEvent()
      showToast({
        type: 'error',
        title: 'There was an issue creating your event. Please try again.',
        subtitle: error.message,
      })
    },
  })

  const {data: pendingEvent} = useGetPendingEvent(
    {_id: pendingEventId!, groupId},
    {
      enabled: !!pendingEventId,
      onError: error => {
        showToast({
          type: 'error',
          title: 'There was an issue loading your event. Please try again.',
          subtitle: error.message,
        })
        navigateToCreateEvent({groupId})
      },
    },
  )

  const {mutateAsync: confirm} = useConfirmPendingEvent()
  const confirmPendingEvent = (groupId: string, status: 'live' | 'draft', shouldNavigateToFinanceSettings: boolean) => {
    // Mixpanel create event events
    trackEvent('Create Event - Launch Event Clicked', {
      status,
      redirectedToFinanceSettings: shouldNavigateToFinanceSettings,
    })

    // Optimizely create event metrics
    if (shouldNavigateToFinanceSettings) trackOptimizelyEvent(EOptimizelyEvents.ENABLE_FINANCE_FOR_CREATE_EVENT)
    else if (status === 'draft') trackOptimizelyEvent(EOptimizelyEvents.SAVE_EVENT_AS_DRAFT)
    else trackOptimizelyEvent(EOptimizelyEvents.LAUNCH_EVENT)

    // Confirm pending event
    confirm({_id: pendingEventId!, groupId, status}).then(res => {
      if (shouldNavigateToFinanceSettings) {
        navigate({pathname: `/owner/groups/${groupId}/finance`})
      } else {
        navigate({pathname: `/e/${res.url}`})
      }
    })
  }

  const {
    control,
    watch,
    getValues: getPendingEventFromForm,
    handleSubmit,
    formState: {dirtyFields, isValid: isFormFilledOut},
    reset,
  } = useForm<EventVisualsForm>({
    defaultValues: transformGetPendingEventOutputToUpdatePendingEventInput(pendingEvent),
    mode: 'onChange',
  })

  const isDirty = Object.keys(dirtyFields).length > 0

  useEffect(() => {
    const defaultValues = transformGetPendingEventOutputToUpdatePendingEventInput(pendingEvent)
    reset(defaultValues)
  }, [pendingEvent === undefined])

  useAutosave(getPendingEventFromForm(), handleSubmit(updatePendingEvent), isFormFilledOut)
  const eventAttributes = getEventVisualsAttributes(watch)
  const {flyer, lightmode, accentColor, eventTitleFont, country, tickets, contactInfo, hasFilledNonControlledFields} =
    eventAttributes

  const isRSVPEvent = useMemo(() => {
    return determineIsRSVPEvent(tickets)
  }, [tickets])
  const progress = useGetCreateEventProgress(control, isRSVPEvent)

  const guestList = pendingEvent?.guestlistPreview ?? DUMMY_ATTENDEES

  const onPressSave = handleSubmit(data => {
    updatePendingEvent(data)
    setIsLaunchModalOpen(true)
    const createEventMixpanelParams = getCreateEventMixpanelParams(data, !!currentUser)
    trackEvent('Create Event - Create Event Clicked', createEventMixpanelParams)
    trackOptimizelyEvent(EOptimizelyEvents.CREATE_EVENT_CLICK)
  })

  const tooltipText = (() => {
    if (!isFormFilledOut) return 'Please fill out the required fields'
    if (!hasFilledNonControlledFields) return 'Please add a flyer'
    if (isUpdatingPendingEvent) return 'Saving...'
  })()

  const actionButton: ActionRowSaveButtonProps = {
    title: isUpdatingPendingEvent ? 'Saving...' : 'Create Event',
    isDisabled: !isFormFilledOut || isUpdatingPendingEvent || !hasFilledNonControlledFields,
    isLoading: isCreatingPendingEvent,
    onPress: onPressSave,
    className: 'gold semiRound',
    tooltipText,
  }

  return (
    <>
      <EventVisualsContextProvider
        lightmode={lightmode ?? false}
        accentColor={accentColor ?? DEFAULT_ACCENT_COLOR}
        fontFamily={eventTitleFont}>
        {pendingEvent && (
          <VisualEditorWrapper className={styles.CreateEventWrapper}>
            <CreateEventActionRow
              isDirty={isDirty}
              actionButton={actionButton}
              {...progress}
              isLightMode={!!lightmode}
            />
            <EventVisualsBackgroundImage flyer={flyer}>
              <EventVisuals.Content className={styles.content}>
                <EventVisuals.Row>
                  <EventFlyerColorSongSection control={control} />
                  <EventVisuals.Column>
                    <EventDetailsFormSection
                      control={control}
                      showShortDescription={isRSVPEvent}
                      showDescription={!isRSVPEvent}
                    />
                    <CreateEventOrganizerDetails control={control} defaultCountry={country} />
                    {isRSVPEvent && <EventVisualsAdmissionSection control={control} country={country} />}
                  </EventVisuals.Column>
                </EventVisuals.Row>
                {!isRSVPEvent && (
                  <EventVisuals.Row>
                    <EventVisualsAdmissionSection control={control} country={country} />
                  </EventVisuals.Row>
                )}
                <FreelancerBooking />
                {isShowingAdvancedSettings && (
                  <>
                    <EventVisuals.Row>
                      <EventSettingsSection control={control} />
                    </EventVisuals.Row>
                    <EventVisuals.Row>
                      <EventVisuals.Column>
                        <EventVisualsActivitySection control={control} />
                      </EventVisuals.Column>
                      <EventVisuals.Column>
                        <EventVisualsGuestlistSection.Expanded attendees={guestList} control={control} />
                      </EventVisuals.Column>
                    </EventVisuals.Row>
                    <EventVisualsCustomSections control={control} watch={watch} showDescription={isRSVPEvent} />
                  </>
                )}
                <h6 onClick={toggleAdvancedSettings} className={`noMargin clickable`} style={{color: accentColor}}>
                  {showAdvancedSettingsText}
                </h6>
              </EventVisuals.Content>
            </EventVisualsBackgroundImage>
          </VisualEditorWrapper>
        )}
      </EventVisualsContextProvider>
      <CreateEventOnboardingOverlay
        isSubmitting={isCreatingPendingEvent}
        isOpen={!pendingEventId}
        onCreateEvent={({isPaidEvent}) => {
          const pendingEventType = isPaidEvent ? 'Paid' : 'RSVP'
          // Set Optimizely user attribute for Create Event Predefined Attributes test
          trackOptimizelyEvent(EOptimizelyEvents.CREATE_EVENT_INITIATED, {
            PendingEventType: pendingEventType,
          })
          createPendingEvent({
            groupId,
            isPaidEvent,
            defaultAttributes,
          })
        }}
      />
      <LaunchEventModal
        control={control}
        isOpen={isLaunchModalOpen}
        onClose={() => setIsLaunchModalOpen(false)}
        defaultEmail={contactInfo?.email ?? undefined}
        defaultPhone={contactInfo?.phone ?? undefined}
        onConfirm={confirmPendingEvent}
      />
    </>
  )
}
