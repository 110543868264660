import React, {useState} from 'react'
import {Helmet} from 'react-helmet'
import ResponsiveGallery from 'react-responsive-gallery'
import {useNavigate, useParams} from 'react-router-dom'

import {CommunityCategory, CommunityEntityResponse} from 'apis/Community'
import useFetchCommunityEntityByUrl from 'apis/Community/useFetchCommunityEntityByUrl'
import {Instagram, LocationPin} from 'components/assets/Icons'
import Button from 'components/form/Button'
import useSessionContext from 'domains/Auth/SessionContext'
import PoshHeader from 'pages/OwnerPage/PoshHeader'

import CommunityFooter from '../Footer'
import ComUAModal from './ComUAModal'

import './styles.scss'

const CommunityProfileLink = (props: {link: string}) => {
  const {link} = props
  return (
    <a className='ComProfile-link' href={link} target='_blank' rel='noreferrer'>
      {link}
    </a>
  )
}

const CommunityProfileLocation = (props: {location: string}) => {
  const {location} = props
  return (
    <div className='ComProfile-info-iconLink invert'>
      <LocationPin />
      <span>{location}</span>
    </div>
  )
}

const CommunityInstagramIcon = (props: {instagram: string}) => {
  const {instagram} = props
  return (
    <div className='ComProfile-info-iconLink'>
      <a href={`https://instagram.com/${instagram}`}>
        <Instagram />
      </a>
    </div>
  )
}

const ProfileHelmet = ({communityEntity}: {communityEntity: CommunityEntityResponse}) => {
  const isVenue = communityEntity.type === 'venue'

  return (
    <Helmet>
      <title>{communityEntity.name} – Posh Community</title>
      <meta name='description' content={`Book ${communityEntity.name} for your upcoming event!`} />
      <meta property='og:title' content={`${communityEntity.name} – Posh Community`} />
      <meta property='og:description' content={`Book ${communityEntity.name} for your upcoming event!`} />
      <meta
        property='og:image'
        content={isVenue && communityEntity.galleryImages ? communityEntity.galleryImages[0].url : communityEntity.avi}
      />
      <meta property='og:url' content={`https://posh.vip/c/${communityEntity.type}/${communityEntity.url}`} />
      <meta property='og:type' content='website' />
      <meta property='twitter:title' content={`${communityEntity.name} – Posh Community`} />
      <meta property='twitter:description' content={`Book ${communityEntity.name} for your upcoming event!`} />
      <meta
        property='twitter:image'
        itemProp='image'
        content={isVenue && communityEntity.galleryImages ? communityEntity.galleryImages[0].url : communityEntity.avi}
      />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content={`https://posh.vip/c/${communityEntity.type}/${communityEntity.url}`} />
      <link rel='canonical' href={`https://posh.vip/c/${communityEntity.type}/${communityEntity.url}`} />
    </Helmet>
  )
}

const CommunityProfile = () => {
  const {communityCategory} = useParams<{communityCategory: CommunityCategory}>()
  const {communityUrl} = useParams<{communityUrl: string}>()
  const {currentUser, userId} = useSessionContext()
  const {data: communityEntity, isFetching} = useFetchCommunityEntityByUrl(communityCategory!, communityUrl!)
  const navigate = useNavigate()
  const [isDisplayingUAModal, setIsDisplayingUAModal] = useState(false)

  if (!communityEntity && !isFetching)
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h1>{`No community ${communityCategory} with URL ${communityUrl} found.`}</h1>
      </div>
    )

  if (!communityEntity) return null

  const isVenue = communityEntity.type === 'venue'
  const getSubHeader = () => {
    const type = communityEntity.type
    switch (type) {
      case 'venue':
        return communityEntity.location
      case 'artist':
        return communityEntity?.tags?.[0]?.value ?? 'House DJ'
      case 'staff':
        return communityEntity?.staffType
    }
  }

  const formattedImagesForComponent = communityEntity.galleryImages?.map(image => {
    return {
      src: image.url,
    }
  })

  const handleBookingRedirect = () => {
    if (currentUser) {
      navigate(`/community/book?c=${communityEntity.type}&u=${communityUrl}`)
    } else {
      setIsDisplayingUAModal(true)
    }
  }

  const screenWidthSizes = {xs: 600, s: 800, m: 1080, l: 1080, xl: 1080, xxl: 3000}
  const numOfImagesPerRow = {xs: 2, s: 2, m: 3, l: 3, xl: 3, xxl: 3}
  const imagesPaddingBottom = {xs: 10, s: 10, m: 10, l: 10, xl: 10, xxl: 10}

  return (
    <>
      <ProfileHelmet communityEntity={communityEntity} />
      <div className='ComProfile'>
        <div className='ComProfile-header'>
          <img
            src={isVenue && communityEntity.galleryImages ? communityEntity.galleryImages[0].url : communityEntity.avi}
          />
          <div />
        </div>
        <PoshHeader community isHalfTranslucent />
        <div className={`ComProfile-inner ${isVenue && 'venue'}`}>
          <img
            className={`ComProfile-Avi ${isVenue && 'venue'}`}
            src={isVenue && communityEntity.galleryImages ? communityEntity.galleryImages[0].url : communityEntity.avi}
          />
          <h1>{communityEntity.name}</h1>
          <p className='text-xl'>{getSubHeader()}</p>
          <div className='ComProfile-info'>
            <CommunityProfileLocation location={communityEntity.location} />
            {communityEntity.website && <CommunityProfileLink link={communityEntity.website} />}
            {communityEntity.instagram && <CommunityInstagramIcon instagram={communityEntity.instagram} />}
          </div>
          <Button style={{marginTop: '10px'}} onClick={handleBookingRedirect}>{`Book ${communityEntity.name}`}</Button>
          <p>{communityEntity.bio}</p>
          {formattedImagesForComponent && formattedImagesForComponent.length > 1 && (
            <>
              <hr />
              <div className='ComProfile-gallery'>
                <ResponsiveGallery
                  images={isVenue ? formattedImagesForComponent.splice(0, 1) : formattedImagesForComponent}
                  screenWidthSizes={screenWidthSizes}
                  numOfImagesPerRow={numOfImagesPerRow}
                  imagesPaddingBottom={imagesPaddingBottom}
                  useLightBox={true}
                />
              </div>
            </>
          )}
        </div>
        <CommunityFooter />
      </div>
      {isDisplayingUAModal && (
        <div>
          <ComUAModal
            isVenue={isVenue}
            communityEntity={communityEntity}
            onClose={() => setIsDisplayingUAModal(false)}
          />
        </div>
      )}
    </>
  )
}

export default CommunityProfile
