import React from 'react'
import Helmet from 'react-helmet'
import ResponsiveGallery from 'react-responsive-gallery'
import {Navigate, useParams} from 'react-router-dom'

import {useFetchGallery} from 'apis/Events/useFetchGallery'
import {useGetEventPhotos} from 'apis/Photos/useGetEventPhotos'
import {PoshLogo} from 'components/PoshLogo'

import {useFetchEventByUrl} from '../../../apis/Events/useFetchEvent'
import useSessionContext from '../../../domains/Auth/SessionContext'
import PoshHeader from '../../OwnerPage/PoshHeader'
import {PhotosOfYou} from './PhotosOfYou'

import './styles.scss'

const GalleryPage = () => {
  const {userId} = useSessionContext()
  const {eventUrl: eventUrlParams} = useParams<{eventUrl: string}>()
  const {data: eventData, isFetching: eventDataIsFetching} = useFetchEventByUrl(eventUrlParams)
  const {data: gallery, isFetching: galleryIsFetching} = useFetchGallery(eventUrlParams ?? '')
  const event = eventData?.event
  const eventId = event?.id
  const {data: eventPhotoData, isFetching: eventPhotosIsFetching} = useGetEventPhotos(
    {eventId: eventId},
    {enabled: !!eventId},
  )
  const eventPhotos =
    eventPhotoData?.map(eventPhoto => ({
      src: eventPhoto.uri,
    })) ?? []
  const cover = eventPhotos[0]?.src ?? ''
  const screenWidthSizes = {xs: 600, s: 800, m: 1080, l: 1080, xl: 1080, xxl: 3000}
  const numOfImagesPerRow = {xs: 1, s: 1, m: 3, l: 3, xl: 3, xxl: 3}

  const imagesPaddingBottom = {xs: 10, s: 10, m: 10, l: 10, xl: 10, xxl: 10}
  const renderPhotographers = gallery && gallery.photographers.length > 0
  const renderTags = gallery && gallery.tagOnInstagram.length > 0

  if (galleryIsFetching || eventDataIsFetching || eventPhotosIsFetching) {
    return null
  }

  const isGalleryEnabled = () => {
    const galleryImagesLength = gallery?.imageUris.length ?? 0
    return galleryImagesLength > 0 || eventPhotos.length > 0
  }

  if (!isGalleryEnabled()) {
    return <Navigate to={`/e/${eventUrlParams}`} />
  }

  return (
    <>
      {event && gallery && (
        <>
          <Helmet>
            <title>{`${event.name} Photos`}</title>
            <meta property='og:title' content={`${event.name} Photos`} />
            <meta property='og:description' content={`${event.name} Photos`} />
            <meta property='og:image' content={cover} />
            <meta property='og:url' content={`https://posh.vip/e/${event.url}/photos`} />
            <meta property='og:type' content='website' />
            <meta property='twitter:title' content={`${event.name} Photos`} />
            <meta property='twitter:description' content={`${event.name} Photos`} />
            <meta property='twitter:image' itemProp='image' content={cover} />
            <meta property='twitter:card' content='summary_large_image' />
            <meta property='twitter:domain' content='posh-react.herokuapp.com' />
          </Helmet>
          <div className='loading'>
            <PoshLogo.Animated />
          </div>
          <PoshHeader />
          <div className='GalleryPage'>
            <div className='GalleryPage-Inner'>
              <h2>{event.name} Photos</h2>
              <p>{gallery.description}</p>

              {/* <iframe src={`https://www.youtube.com/embed/${gallery.recap}`} /> */}

              <hr />

              {renderPhotographers && (
                <div className='GalleryPage-Info'>
                  <span>Photographers</span>
                  <div>
                    {gallery.photographers.map((photographer, index) => (
                      <div key={index}>
                        <a
                          className='GalleryPage-Info-Photog'
                          href={
                            photographer.link.includes('https://') ? photographer.link : 'https://' + photographer.link
                          }
                          target='_blank'
                          rel='noreferrer'>
                          {photographer.name}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {renderTags && (
                <div className='GalleryPage-Info'>
                  <span>Tag When Posting</span>
                  <div>
                    {gallery.tagOnInstagram.map((tag, index) => (
                      <a
                        className='GalleryPage-Info-Tag'
                        href={`https://instagram.com/${tag}`}
                        target='_blank'
                        key={index}
                        rel='noreferrer'>
                        <span>{tag}</span>
                      </a>
                    ))}
                  </div>
                </div>
              )}

              {renderTags || (renderPhotographers && <hr />)}

              <PhotosOfYou
                eventId={eventId}
                screenWidthSizes={screenWidthSizes}
                numOfImagesPerRow={numOfImagesPerRow}
                imagesPaddingBottom={imagesPaddingBottom}
                useLightBox={true}
              />

              <ResponsiveGallery
                images={eventPhotos ?? []}
                screenWidthSizes={screenWidthSizes}
                numOfImagesPerRow={numOfImagesPerRow}
                imagesPaddingBottom={imagesPaddingBottom}
                useLightBox={true}
              />
            </div>
          </div>
          <div className={'EventPage-footer'}>
            <hr />
            <div className='EventPage-footer-rowB'>
              <a href='https://posh.vip'>
                <img src='https://assets.posh.vip/b2/rect-type-gray.svg' />
              </a>
              <a href='/signup'>Launch An Event</a>
              <a href='/tos'>Terms Of Service</a>
              <a href='/privacy'>Privacy Policy</a>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default GalleryPage
