import React, {useState} from 'react'

// pick a date util library
import MomentUtils from '@date-io/moment'
import {MuiThemeProvider} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles'
import {KeyboardDateTimePicker} from '@material-ui/pickers'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
/* eslint-disable @typescript-eslint/no-empty-interface */
import {MuiPickersOverrides} from '@material-ui/pickers/typings/overrides'
import classNames from 'classnames'
import {isUndefined} from 'lodash'
import moment from 'moment-timezone'

import PoshInfo from '../PoshInfo'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof Required<MuiPickersOverrides>[P]
}
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends Required<overridesNameToClassKey> {}
}
export const customTheme = createTheme({
  shape: {
    borderRadius: 20,
  },
  palette: {
    primary: {
      main: '#181818',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#f00',
      paper: '#333333',
    },
    secondary: {
      main: '#fc0',
    },
    action: {
      active: '#7a7b7d',
      hover: '#7a7b7d',
      selected: '#7a7b7d',
      disabled: '#B9BCBD',
      disabledBackground: '#FFFFFF',
      focus: '#ffcc00',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      disabled: '#B9BCBD',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  overrides: {
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#A3A3A3',
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        backgroundColor: '#0000',
        borderRadius: '50px',
        border: '1px solid #7a7b7d',
      },
      input: {
        padding: '22px 14px',
        fontWeight: 'lighter',
        fontSize: '16px',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#FFFFFF',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#fc0',
        color: '#181818',
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        border: '14px solid #000000',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: '#fc0',
      },
    },
  },
})

interface PoshDatePickerProps {
  className?: string
  name?: string
  value?: string | undefined
  setDates: (localDate: string, utcDate: string) => void
  timezone: string
  noWrappingContainer?: boolean
  disabled?: boolean
  white?: boolean
  noHours?: boolean
  label?: string
  info?: string
  maxDate?: Date
  minDate?: Date
}

const generateUtcDate = (
  eventTimezoneOffset: number,
  localTimezoneOffset: number,
  date?: moment.Moment | undefined,
  currentTimezone?: string,
) => {
  if (!currentTimezone || isUndefined(currentTimezone)) return date?.format().toString()
  if (eventTimezoneOffset === localTimezoneOffset) return date?.format().toString()
  else
    return date
      ?.add(localTimezoneOffset - eventTimezoneOffset, 'minute')
      .format()
      .toString()
}

const PoshDatePicker = (props: PoshDatePickerProps) => {
  const {label, info, name, value, setDates, timezone, disabled, white, className, noHours, minDate, maxDate} = props
  const [displayedDate, setDisplayedDate] = useState<string | null>(null)
  const pattern = noHours ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss.SS'
  const format = noHours ? 'MM/DD/yyyy' : 'MM/DD/yyyy hh:mm a'
  const inputWrapperClassName = !!label ? 'inputLabelWrapper' : 'inputWrapper fullWidth'
  const Icon = () => {
    return (
      <img
        src={`https://assets.posh.vip/b2/calendar-${white ? 'white' : 'grey'}.svg`}
        style={{width: '18px', height: '18px'}}
      />
    )
  }

  return (
    <div className={classNames(inputWrapperClassName, className)}>
      {label && (
        <div className={inputWrapperClassName + '-labelContatiner'}>
          <label className='noMargin'>{label}</label>
          {info && <PoshInfo info={info} />}
        </div>
      )}
      <div style={{width: '100%'}}>
        <MuiThemeProvider theme={customTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              inputVariant='outlined'
              InputProps={{
                disableUnderline: true,
              }}
              type='datetime'
              format={format}
              placeholder={name}
              value={displayedDate ?? value ?? null}
              keyboardIcon={<Icon />}
              onChange={e => {
                const datePicked = e?.clone()
                const currentTimezone = moment.tz.guess()
                const localTimezoneOffset = moment.tz(currentTimezone).utcOffset()
                const eventTimezoneOffset = moment.tz(timezone).utcOffset()
                setDisplayedDate(datePicked?.local(true).format(pattern).toString() ?? null)
                const date: string | undefined = datePicked?.local(false).format().toString().slice(0, 19) + 'Z'
                const utcDate: string | undefined = generateUtcDate(
                  eventTimezoneOffset,
                  localTimezoneOffset,
                  datePicked?.utc(false),
                  currentTimezone,
                )
                setDates(date!, utcDate!)
              }}
              disabled={disabled ?? false}
              emptyLabel={disabled ? '' : name}
              minDate={minDate}
              maxDate={maxDate}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </div>
    </div>
  )
}

export default PoshDatePicker
