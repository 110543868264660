import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import getAvi from 'apis/Util/getAvi'
import classnames from 'classnames'
import {PoshLogo} from 'components/PoshLogo'
import useSessionContext from 'domains/Auth/SessionContext'
import usePermissionsContext from 'domains/Teams/PermissionsContext'
import {handleAuthNavigation} from 'helpers/handleAuthNavigation'
import {trackCreateEventEntryPoint, useNavigateToCreateEvent} from 'pages/CreateEvent/useNavigateToCreateEvent'

import {HomePageComponentProps} from '../interface'

import './styles.scss'

const HamburgerMenu = ({
  Links,
  isClosingMenu,
  closeHambugerMenu,
}: {
  Links: () => JSX.Element
  isClosingMenu: boolean
  closeHambugerMenu: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <div
      className={classnames('LandingPageHamburgerMenu', {
        closing: isClosingMenu,
      })}>
      <img
        src='https://assets.posh.vip/b2/x.svg'
        className='LandingPageHamburgerMenu-x'
        onClick={() => closeHambugerMenu(false)}
      />
      <PoshLogo.Link />
      <div className='LandingPageHamburgerMenu-links'>
        <Links />
      </div>
    </div>
  )
}

interface HomePageNavigationProps extends HomePageComponentProps {
  hasBegunScrolling: boolean
}

const HomePageNavigation = (props: HomePageNavigationProps) => {
  const {hasBegunScrolling, changePage} = props
  const navigate = useNavigate()
  const {createEventUrl} = useNavigateToCreateEvent()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false)
  const [isClosingMenu, setIsClosingMenu] = useState(false)

  const {setAccountRole} = usePermissionsContext()
  const {currentUser, accountRoles} = useSessionContext()
  const avi = getAvi(currentUser)

  const closeHambugerMenu = () => {
    setIsClosingMenu(true)
    setTimeout(() => {
      setIsHamburgerOpen(false)
      setIsClosingMenu(false)
    }, 1000)
  }

  const handleNav = () => {
    const navLink = handleAuthNavigation({
      accountRoles,
      setAccountRole,
    })
    navigate(navLink)
  }

  const Links = () => {
    return (
      <>
        <a onClick={() => changePage('/explore')}>Explore Events</a>
        {/* <a>Kickback</a>
        <a>About Us</a> */}
        <a href='https://calendly.com/d/5k4-6w5-ft2' target='_blank' rel='noreferrer'>
          Request A Demo
        </a>
        <a href='https://hosted.posh.vip' target='_blank' rel='noreferrer'>
          Magazine
        </a>
        {!currentUser && <a onClick={() => navigate('/login')}>Login</a>}
        <a
          onClick={() => {
            trackCreateEventEntryPoint('Landing Page')
            changePage(createEventUrl)
            trackMixpanelEvent('Create Event- Posh home page Header')
          }}>
          Launch An Event
        </a>
        {currentUser && <img className='LandingPage-nav-pp' src={avi} onClick={() => handleNav()} />}
      </>
    )
  }

  return (
    <>
      <div className={`LandingPage-nav ${hasBegunScrolling ? 'scrolling' : ''}`}>
        <div className={`LandingPage-nav-inner ${hasBegunScrolling ? 'scrolling' : ''}`}>
          <PoshLogo.Link />
          <div className='LandingPage-nav-links'>
            <Links />
          </div>
          <div
            className={`LandingPage-nav-hamburger ${isHamburgerOpen && !isClosingMenu ? 'open' : ''}`}
            onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}>
            <img src='https://assets.posh.vip/b3/icons/gold-hamburger.svg' />
          </div>
        </div>
      </div>
      {isHamburgerOpen && (
        <HamburgerMenu Links={Links} isClosingMenu={isClosingMenu} closeHambugerMenu={closeHambugerMenu} />
      )}
    </>
  )
}

export default HomePageNavigation
