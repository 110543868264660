import React, {useEffect, useMemo} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {useCheckForNewApprovals} from 'apis/Events/useCheckEventHasNewApprovals'
import {useMixpanel} from 'apis/MixPanelHandler'
import classNames from 'classnames'
import {ArrowLeft, CircularAlert, Eye} from 'components/assets/Icons'
import {KickbackIcon} from 'components/assets/KickbackIcon'
import useSessionContext from 'domains/Auth/SessionContext'
import isDevEnv from 'helpers/isDevEnv'
import {isEventKickbackEligible} from 'helpers/isEventKickbackEligible'
import {includes} from 'lodash'
import moment from 'moment'

import useFetchEvent from '../../apis/Events/useFetchEvent'
import RequirePermissions from '../../components/RequirePermissions'
import {useResourcePageParams} from '../PoshAppLayout'
import {EventPageSection, EVENTS_ROUTE_MAP} from './EventRoutes'

import './EventPageNavigation.scss'

/** The icons in nav are styled with class names who set the color
 * to the midpoint between white and black, in hex that midpoint is 808080
 */
export const MIDDLE_GRAY = '#808080'

const EventPageNavigation = () => {
  const {pathname} = useLocation()
  const {domain, eventId, groupId} = useResourcePageParams()
  const navigate = useNavigate()
  const {data: event} = useFetchEvent(eventId!)
  const {data} = useCheckForNewApprovals({eventId: eventId!}, {enabled: !!eventId})
  const pendingOrders = data?.pendingOrders || 0
  const {currentUser} = useSessionContext()
  const eventPageLink =
    domain === 'host' ? `/e/${event?.event?.url}?t=${currentUser?.trackingLink}` : `/e/${event?.event?.url}`

  const hasAtLeastOneTicketWithApproval = useMemo(
    () => event?.event?.tickets?.some(ticket => ticket.approvalRequired),
    [event?.event?.tickets],
  )
  const shouldShowApprovalTab = hasAtLeastOneTicketWithApproval || pendingOrders > 0
  const shouldShowApprovalNotification = pendingOrders > 0

  const selectedSection = pathname.substring(
    pathname.lastIndexOf('events') + 7 + eventId!.length + 1,
  ) as EventPageSection
  const mainSelectedSection = selectedSection.substring(
    0,
    selectedSection.indexOf('/') >= 0 ? selectedSection.indexOf('/') : selectedSection.length,
  )
  const subSection = selectedSection.substring(selectedSection.lastIndexOf('/') + 1)
  const sections = Object.values(EventPageSection)
  const search = useLocation().search
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const timezone = event?.event.timezone || 'America/New_York'
  const eventIsOver = moment.tz(event?.event.endUtc, timezone).isBefore(moment())
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const EventPageSectionToMixpanelMap = {
    [EventPageSection.OVERVIEW]: () => trackMixpanelEvent('Overview Sidebar Click- Event Edit Page'),
    [EventPageSection.VISUALS]: () => trackMixpanelEvent('Visuals Sidebar Click- Event Edit Page'),
    [EventPageSection.TICKETS]: () => trackMixpanelEvent('Tickets Sidebar Click- Event Edit Page'),
    [EventPageSection.ATTENDEES]: () => trackMixpanelEvent('Attendees Sidebar Click- Event Edit Page'),
    [EventPageSection.TEAM_MEMBERS]: () => trackMixpanelEvent('Team Sidebar Click- Event Edit Page'),
    [EventPageSection.KICKBACK]: () => trackMixpanelEvent('Kickback Sidebar Click- Event Edit Page'),
    [EventPageSection.APPROVALS]: () => {},
    [EventPageSection.TABLES]: () => {},
    [EventPageSection.SETTINGS]: () => trackMixpanelEvent('Settings Sidebar Click- Event Edit Page'),
    [EventPageSection.SERVICES]: () => {},
    [EventPageSection.GALLERY]: () => {},
  }

  const onClickEventEyeButton = () => {
    window.open(eventPageLink + search, '_blank')
    trackMixpanelEvent('View Event Page Sidebar Click- Event Edit Page')
  }

  return (
    <div className='EventPageNavigation'>
      <ReactTooltip className='poshToolTip' effect='solid' place='right' />
      <a
        style={{cursor: 'pointer'}}
        onClick={() => {
          if (!includes(sections, subSection)) {
            navigate(-1)
          } else {
            navigate(`/${domain}/groups/${groupId}/overview` + search)
          }
        }}>
        <ArrowLeft className='EventPageNavigation-backButton' />
      </a>

      <div className='EventPageNavigation-navItemContainer'>
        {Object.keys(EVENTS_ROUTE_MAP).map(eventSection => {
          const {icon, activeIcon, requiredPermissions, tooltip, showAlert} =
            EVENTS_ROUTE_MAP[eventSection as EventPageSection]
          const isActive = mainSelectedSection == eventSection
          const getId = () => {
            if (isActive) return 'selected'
            else return undefined
          }
          const isKickback = eventSection === 'kickback'
          if (!event?.venue && eventSection == EventPageSection.TABLES) return null
          if (event?.event?.isRSVPEvent && eventSection == EventPageSection.TICKETS) return null
          if (
            !isEventKickbackEligible({isRSVPEvent: event?.event?.isRSVPEvent, country: event?.group.country}) &&
            eventSection == EventPageSection.KICKBACK
          )
            return null
          if (!shouldShowApprovalTab && eventSection == EventPageSection.APPROVALS) return null
          if (!eventIsOver && eventSection == EventPageSection.GALLERY) return null
          // TODO: Remove feature flag when Clik goes live
          if (!isDevEnv() && eventSection == EventPageSection.SERVICES) return null
          if (requiredPermissions) {
            return (
              <RequirePermissions requiredPermissions={requiredPermissions} key={eventSection}>
                <Link
                  id={getId()}
                  to={`/${domain}/groups/${groupId}/events/${eventId}/${eventSection}` + search}
                  className={`EventPageNavigation-navItemContainer-link ${isKickback && 'kickback'}`}
                  onClick={EventPageSectionToMixpanelMap[eventSection as EventPageSection]}>
                  {eventSection === EventPageSection.KICKBACK ? (
                    <KickbackIcon
                      size={30}
                      primaryColor={isActive ? 'white' : MIDDLE_GRAY}
                      secondaryColor={isActive ? MIDDLE_GRAY : 'black'}
                    />
                  ) : (
                    <img
                      data-tip={tooltip}
                      className='EventPageNavigation-navItemContainer-link-icon'
                      src={isActive ? activeIcon : icon}
                    />
                  )}
                  {eventSection == EventPageSection.APPROVALS && shouldShowApprovalNotification && (
                    <div
                      className={`EventPageNavigation-navItemContainer-link-newApprovals ${
                        isActive ? 'isActive' : ''
                      }`}>
                      {pendingOrders}
                    </div>
                  )}
                </Link>
              </RequirePermissions>
            )
          } else {
            return (
              <Link
                id={getId()}
                key={eventSection}
                to={`/${domain}/groups/${groupId}/events/${eventId}/${eventSection}` + search}
                className='EventPageNavigation-navItemContainer-link'>
                {showAlert && (
                  <CircularAlert
                    className={classNames('EventPageNavigation-navItemContainer-link-alert', {inactive: !isActive})}
                  />
                )}
                <img
                  data-tip={tooltip}
                  className='EventPageNavigation-navItemContainer-link-icon'
                  src={isActive ? activeIcon : icon}
                />
              </Link>
            )
          }
        })}
      </div>
      <a style={{cursor: 'pointer'}} onClick={onClickEventEyeButton}>
        <Eye className='EventPageNavigation-eye' color={MIDDLE_GRAY} />
      </a>
    </div>
  )
}

export default EventPageNavigation
