import React, {ReactNode, useMemo, useState} from 'react'
import {useMediaQuery} from 'react-responsive'

import {SelectedSecondaryFilter, SortOption, WhenOption, WhereOption} from '@posh/types'
import {useFetchFeaturedEvents} from 'apis/Events/EventMarketplace/useFetchFeaturedEvents'
import {useFetchMarketplaceSidebarFilters} from 'apis/Events/EventMarketplace/useFetchMarketplaceSidebarFilters'
import {searchIconUrl} from 'assets/images/s3images'
import {sparklerIcon} from 'components/assets/Icons'
import {whiteStarIcon as starIconUrl} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {PoshBoatLoader} from 'components/Loaders/PoshBoatLoader'
import TextIconInput from 'components/TextInput'
import {useQueryParams} from 'hooks/QueryParams/useQueryParams'
import {FeaturedEvents} from 'pages/EventMarketplace/FeaturedEvents'
import {FilterButton} from 'pages/EventMarketplace/FilterButton'
import {useGetUserCoordinates} from 'pages/EventMarketplace/hooks/useGetUserCoordinates'
import {useSetMarketplaceStateFromQueryParams} from 'pages/EventMarketplace/hooks/useSetMarketplaceStateFromQueryParams'
import {EventMarketplaceMainFilters, MainFilters} from 'pages/EventMarketplace/MainFilters'
import {SidebarEventFilter} from 'pages/EventMarketplace/SidebarFilters'
import PoshHeader from 'pages/OwnerPage/PoshHeader'

import {FeaturedEventsMobile, MarketplaceEvents} from './MarketplaceEvents'

import './styles.scss'

export const SMALL_SCREEN_WIDTH = '840px'
const filterIconUrl = ' https://assets.posh.vip/b2/filter.svg'

const MagicSearchItem = ({label, icon, ...props}: React.HTMLProps<HTMLDivElement> & {label: string; icon?: string}) => {
  return (
    <div {...props}>
      {icon !== 'none' && <img src={icon ? icon : sparklerIcon} />}
      <p>{label}</p>
    </div>
  )
}
const MagicSearch = ({
  onClick,
  isBrowsingMobileFeatured,
}: {
  onClick: (which: 'featured' | 'filter' | 'browse') => void
  isBrowsingMobileFeatured: boolean
}) => {
  const isMobileScreen = useMediaQuery({query: `(max-width: ${SMALL_SCREEN_WIDTH})`})

  if (isBrowsingMobileFeatured) {
    return (
      <div className='MagicSearch' style={{position: 'sticky', top: 0, zIndex: 22, background: '#0a0b0d'}}>
        <MagicSearchItem
          label='←BROWSE'
          icon={'none'}
          className='MagicSearch-Featured'
          onClick={() => onClick('browse')}
        />
      </div>
    )
  }

  if (isMobileScreen) {
    return (
      <div className='MagicSearch'>
        <MagicSearchItem
          label='FEATURED'
          icon={starIconUrl}
          className='MagicSearch-Featured'
          onClick={() => onClick('featured')}
        />
        <MagicSearchItem
          label='FILTER'
          icon={filterIconUrl}
          className='MagicSearch-Filter'
          onClick={() => onClick('filter')}
        />
      </div>
    )
  } else {
    return null
  }
}

const FilteringBar = ({children, onClick}: {children?: ReactNode; onClick: () => void}) => {
  return (
    <div className='FilteringBar' onClick={onClick}>
      {children}
    </div>
  )
}

const IS_NEW_SEARCH_DESIGN_DONE = false
export const EventMarketplace = () => {
  useFetchFeaturedEvents() // To preemptively fetch data
  const {queryParams, setQueryParams} = useQueryParams()
  const [search, setSearch] = useState('')
  const [filteringPanelOpen, setFilteringPanelOpen] = useState(false)
  const [isBrowsingMobileFeatured, setIsBrowsingMobileFeatured] = useState(false)
  const [mainFilters, setMainFilters] = useState<EventMarketplaceMainFilters>({
    sort: 'Trending',
    when: 'This Week',
    where: 'New York City',
  })
  const withNeighborhoods = mainFilters.where === 'New York City'
  // Use boolean instead of the where itself to avoid re-fetching
  const {data: sidebarFiltersData, isInitialLoading} = useFetchMarketplaceSidebarFilters({
    withNeighborhoods: withNeighborhoods,
  })
  const [secondaryFilters, setSecondaryFilters] = useState<SelectedSecondaryFilter[]>([])

  const onFilterSelected = (filter: SelectedSecondaryFilter) => {
    const {name, chosenValues} = filter
    if (chosenValues.length === 0) {
      setSecondaryFilters(secondaryFilters.filter(f => f.name !== name))
      setQueryParams(name, '')
      return
    }
    const currentIndexForFilter = secondaryFilters.findIndex(f => f.name === name)
    const isFilterActive = currentIndexForFilter !== -1
    if (isFilterActive) {
      const newFilters = [...secondaryFilters]
      newFilters[currentIndexForFilter] = filter
      setSecondaryFilters(newFilters)
    } else {
      setSecondaryFilters([...secondaryFilters, filter])
    }
    setQueryParams(name, chosenValues)
  }

  const {coordinates, setCoordinates} = useGetUserCoordinates(mainFilters.where)

  useSetMarketplaceStateFromQueryParams({
    setSearch,
    setMainFilters,
    setSecondaryFilters,
    secondaryFilters,
    sidebarFiltersData,
  })

  const isMobileScreen = useMediaQuery({query: `(max-width: ${SMALL_SCREEN_WIDTH})`})
  const showEvents = !(isMobileScreen && filteringPanelOpen) && !isBrowsingMobileFeatured
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setQueryParams('search', e.target.value)
  }

  const onUpdateFilter = (filter: keyof EventMarketplaceMainFilters, value: SortOption | WhenOption | WhereOption) => {
    setMainFilters(mainFilters => ({...mainFilters, [filter]: value}))
    setQueryParams(filter, value)
  }

  const onClickMagicSearchButton = (which: 'featured' | 'filter' | 'browse') => {
    if (which === 'featured') {
      setIsBrowsingMobileFeatured(true)
    } else if (which === 'filter') {
      onToggleFilterPanel()
    } else if (which === 'browse') {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      setIsBrowsingMobileFeatured(false)
    }
  }

  const onToggleFilterPanel = (e?: React.MouseEvent<HTMLButtonElement | HTMLHeadElement>) => {
    e && e.stopPropagation()
    setFilteringPanelOpen(!filteringPanelOpen)
  }

  const onClickFilteringBar = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }

  const PoshHeaderClassName = useMemo(() => {
    if (isMobileScreen) {
      if (isBrowsingMobileFeatured) {
        return 'PoshHeader-Marketplace-mobile-browsingFeatured'
      } else {
        return 'PoshHeader-Marketplace-mobile'
      }
    }
  }, [isMobileScreen, isBrowsingMobileFeatured])
  return (
    <div>
      <PoshHeader isTranslucent className={PoshHeaderClassName} desktopOnly>
        {IS_NEW_SEARCH_DESIGN_DONE && (
          <div className='SearchBarContainer'>
            <TextIconInput
              placeholder='Search by Event Name, Organizer, Performing Artists, Etc.'
              value={search}
              onChange={onChangeSearch}
              icon={searchIconUrl}
            />
          </div>
        )}
      </PoshHeader>
      <div className='Epl_MobileCTA'>
        <span>
          Find the best events on the
          <br />
          Posh App
        </span>
        <div className='Epl_MobileCTA-MAL-CTAs'>
          <a href='https://posh.vip/cpn/pappeb'>
            <img src='https://assets.posh.vip/b3/app-ctas/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' />
          </a>
        </div>
      </div>
      {!isMobileScreen && <FeaturedEvents />}
      <div style={{display: 'flex', position: 'relative'}}>
        {filteringPanelOpen && (
          <div className='FilteringPanel'>
            {isInitialLoading ? (
              <PoshBoatLoader />
            ) : (
              <>
                {sidebarFiltersData?.eventFilters.map(filter => (
                  <SidebarEventFilter
                    key={filter.id}
                    name={filter.name}
                    selectionVariant={filter.selectionVariant}
                    options={filter.options}
                    onFilterSelected={onFilterSelected}
                    defaultValues={secondaryFilters.find(f => f.name === filter.name)?.chosenValues ?? []}
                  />
                ))}
              </>
            )}
            <Button onClick={onToggleFilterPanel} className='gold' style={{alignSelf: 'flex-end'}}>
              Apply
            </Button>
          </div>
        )}
        <div className='ResultsPanel'>
          <MagicSearch onClick={onClickMagicSearchButton} isBrowsingMobileFeatured={isBrowsingMobileFeatured} />
          {!isBrowsingMobileFeatured && (
            <FilteringBar onClick={onClickFilteringBar}>
              <FilterButton onClick={onToggleFilterPanel} />
              <MainFilters {...mainFilters} onUpdateFilter={onUpdateFilter} setCoordinates={setCoordinates} />
              {/* Hiding this filter button let's us match the designs here without sacrificing flexbox */}
              <FilterButton />
            </FilteringBar>
          )}
          {showEvents && (
            <MarketplaceEvents
              {...mainFilters}
              search={search}
              coordinates={coordinates}
              secondaryFilters={secondaryFilters}
            />
          )}
          {isBrowsingMobileFeatured && <FeaturedEventsMobile />}
        </div>
      </div>
    </div>
  )
}
