import React, {useState} from 'react'

import classNames from 'classnames'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {PoshHelmet} from 'components/PoshHelmet/PoshHelmet'
import {LoginLogo, LoginSvg} from 'pages/LoginPage/LoginLinks'

import {useSendPasswordEmail} from '../../../apis/Accounts/useSendPasswordEmail'

import './index.scss'

const SUCCESS_MESSAGE =
  'If an account with that email exists, we will send you an email with a link to reset your password.'

const RequestPasswordReset = () => {
  const [formEmail, setFormEmail] = useState<string>('')
  const [message, setMessage] = useState<{message: string; type: 'ok' | 'err'} | undefined>()

  const {mutate: send, isLoading} = useSendPasswordEmail({
    onMutate: () => setMessage(undefined),
    onSuccess: () => {
      return setMessage({message: SUCCESS_MESSAGE, type: 'ok'})
    },
    onError: error => {
      return setMessage({message: error.message, type: 'err'})
    },
  })

  const onSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (formEmail.length === 0)
      return setMessage({
        message: 'Please enter an email address.',
        type: 'err',
      })
    send({email: formEmail.toLowerCase()})
  }

  return (
    <>
      <PoshHelmet title='POSH - Reset Password' />
      <div className='RequestPasswordReset'>
        <LoginSvg />
        <form className='RequestPasswordReset-container'>
          <LoginLogo />
          {message ? (
            <>
              <p
                className={classNames('center noMargin', {
                  error: message.type === 'err',
                })}>
                {message.message}
              </p>
              <a href='/login'>Return to login</a>
            </>
          ) : (
            <>
              <label className='white'>What is your email?</label>
              <Input
                type='text'
                className='square'
                value={formEmail}
                onChange={e => setFormEmail(e.target.value)}
                placeholder='Email'
              />
              <Button
                className='gold fullWidth'
                type='submit'
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onSubmit}>
                Reset Password
              </Button>
            </>
          )}
        </form>
      </div>
    </>
  )
}

export default RequestPasswordReset
