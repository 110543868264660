import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'

import {zodResolver} from '@hookform/resolvers/zod'
import {formatEventPlaylistDate} from '@posh/model-types'
import {Currency} from '@posh/utils'
import {
  GetEventsForEventPlaylistOutput,
  useGetEventsForEventPlaylist,
} from 'apis/Events/playlists/manage/useGetEventsForEventPlaylist'
import {useSearchForEvents} from 'apis/search/useSearchForEvents'
import {InputContainer} from 'components/DynamicForm/Inputs/shared/InputContainer/InputContainer'
import {TextInput} from 'components/DynamicForm/Inputs/TextInput/TextInput'
import Button from 'components/form/Button'
import {WithToolTip} from 'components/form/PoshInfo'
import {useToast} from 'components/toasts/ToastProvider'
import VerifiedGroupBadge from 'components/VerifiedGroupBadge'
import {CrossSection} from 'pages/EventManagementPages/OverviewUpdate/Analytics/CrossSection/CrossSection'
import {Text} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/Text/Text'
import {parseNumberValue} from 'pages/EventManagementPages/OverviewUpdate/Analytics/Shared/utils'
import {z} from 'zod'

import styles from './EventPlaylistSearchEvents.module.scss'

const ICON_SIZE = 16

interface EventPlaylistSearchEventsProps {
  existingEventIds: string[]
  onAddEvents: (eventIds: string[]) => void
}

export const EventPlaylistSearchEvents = (props: EventPlaylistSearchEventsProps) => {
  const {existingEventIds, onAddEvents} = props

  const [isAddingByEventIds, setIsAddingByEventIds] = useState(false)
  const [search, setSearch] = useState('')

  const {data: eventsSearched} = useSearchForEvents(
    {
      searchQuery: search,
      showPreviousEvents: false,
      limit: 20,
    },
    {
      // Search is not available until 2 characters are entered
      enabled: search.length > 1,
    },
  )

  const {showToast} = useToast()

  const [events, setEvents] = useState<GetEventsForEventPlaylistOutput['events']>([])

  const {mutate: getEventsForEventPlaylist} = useGetEventsForEventPlaylist({
    onSuccess: data => {
      setEvents(data.events)
    },
    onError: error => {
      showToast({
        type: 'error',
        title: 'Something went wrong fetching events for event playlist.',
        subtitle: error.message,
      })
    },
  })

  useEffect(() => {
    if (!!eventsSearched?.events.length) {
      getEventsForEventPlaylist({
        ids: eventsSearched?.events.map(event => event.id) ?? [],
        sortOrder: ['date'],
      })
    }
  }, [eventsSearched])

  const toggleAddingByEventIds = () => {
    setIsAddingByEventIds(prev => !prev)
  }

  const isEventAlreadyAdded = (eventId: string) => existingEventIds.includes(eventId)

  return (
    <div className={styles.EventPlaylistSearchEvents}>
      <div className={styles.Header}>
        <h3 className='noMargin'>Add Events</h3>
        <Button style={{width: 200}} onClick={() => toggleAddingByEventIds()} className='gold'>
          {isAddingByEventIds ? 'Add By Search' : 'Add By Event ID(s)'}
        </Button>
      </div>

      {isAddingByEventIds ? (
        <EventPlaylistAddByEventIds
          existingEventIds={existingEventIds}
          onAddEvents={eventIds => {
            onAddEvents(eventIds)
          }}
        />
      ) : (
        <>
          <TextInput placeholder='Search for events... (min 2 char.)' value={search} onChange={setSearch} />
          {events.length > 0 && (
            <div className={styles.EventsContainer}>
              {events.map(event => (
                <div className={styles.EventCard} key={event.url}>
                  <div className={styles.EventAttributesContainer}>
                    <img src={event.flyer} alt={`${event.name} Flyer`} />
                    <div className={styles.EventAttributes}>
                      <Link to={`/e/${event.url}`} style={{color: 'white'}} target='_blank'>
                        <Text bold size='large'>
                          {event.name}
                        </Text>
                      </Link>
                      <div className={styles.GroupAttributes}>
                        <img src={event.groupAvi} alt={`${event.groupName} Profile Picture`} />
                        <Text>
                          <Link to={`/g/${event.groupUrl}`} style={{color: 'white'}} target='_blank'>
                            {event.groupName ?? '[No group name]'}
                          </Link>
                        </Text>
                        {event.groupVerified && <VerifiedGroupBadge size={ICON_SIZE} />}
                      </div>
                    </div>
                    <div>
                      {isEventAlreadyAdded(event.id) ? (
                        <Button disabled>Added</Button>
                      ) : (
                        <Button onClick={() => onAddEvents([event.id])}>Add</Button>
                      )}
                    </div>
                  </div>
                  <CrossSection.Section>
                    <CrossSection>
                      <CrossSection.Row>
                        <Text>Start Date</Text>
                      </CrossSection.Row>
                      <CrossSection.Row>
                        <Text size='large' bold>
                          {Object.values(formatEventPlaylistDate(event.startUtc, event.timezone)).join(' ')}
                        </Text>
                      </CrossSection.Row>
                    </CrossSection>
                    <CrossSection>
                      <CrossSection.Row>
                        <Text>Location</Text>
                      </CrossSection.Row>
                      <CrossSection.Row>
                        <Text size='large' bold>
                          {event.location || 'No location'}
                        </Text>
                      </CrossSection.Row>
                    </CrossSection>
                    <CrossSection>
                      <CrossSection.Row>
                        <Text>Tickets Sold</Text>
                        <WithToolTip info={'The number of tickets sold so far for this event.'}>
                          <CrossSection.Icon name='info' />
                        </WithToolTip>
                      </CrossSection.Row>
                      <CrossSection.Row>
                        <Text size='large' bold>
                          {parseNumberValue({type: 'number', value: event.ticketsSold})}
                        </Text>
                      </CrossSection.Row>
                    </CrossSection>
                    <CrossSection>
                      <CrossSection.Row>
                        <Text>Group GMV</Text>
                        <WithToolTip
                          info={`This value is accurate within the past day. This is the total GMV the group has produced.`}
                          align='left'>
                          <CrossSection.Icon name='info' />
                        </WithToolTip>
                      </CrossSection.Row>
                      <CrossSection.Row>
                        <Text size='large' bold>
                          {Currency.format(event.groupGmv, event.groupCurrency)}
                        </Text>
                      </CrossSection.Row>
                    </CrossSection>
                  </CrossSection.Section>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

interface EventPlaylistAddByEventIdsProps {
  existingEventIds: string[]
  onAddEvents: (eventIds: string[]) => void
}

const EventPlaylistAddByEventIds = (props: EventPlaylistAddByEventIdsProps) => {
  const {existingEventIds, onAddEvents} = props

  const eventIdsSchema = z.object({
    eventIds: z
      .string()
      .transform(value => value.split(',').map(id => id.trim()))
      .pipe(z.string().array())
      .refine(ids => ids.length > 0, {message: 'At least one event ID is required.'})
      .refine(ids => new Set(ids).size === ids.length, {message: 'Event IDs must be unique.'})
      .refine(ids => ids.every(id => id.length === 24), {message: 'Event IDs must be a valid ObjectId.'})
      .refine(ids => ids.every(id => !existingEventIds.includes(id)), {
        message: 'An event with this ID already exists.',
      }),
  })

  const form = useForm({
    defaultValues: {
      eventIds: '',
    },
    resolver: zodResolver(eventIdsSchema),
  })

  return (
    <div>
      <InputContainer error={form.formState.errors.eventIds?.message}>
        <textarea
          placeholder='Enter event IDs separated by commas...'
          className={styles.EventIdsInputTextArea}
          {...form.register('eventIds')}
        />
      </InputContainer>
      <Button
        style={{marginTop: 20}}
        onClick={form.handleSubmit(({eventIds}) => {
          onAddEvents(eventIds as unknown as string[])
          form.reset()
        })}>
        Add Events
      </Button>
    </div>
  )
}
