import React from 'react'
import {useNavigate} from 'react-router-dom'

import {CreateEventPlaylistInput} from 'apis/Events/playlists/manage/useCreateEventPlaylist'
import {GetEventPlaylistForEditorOutput} from 'apis/Events/playlists/manage/useGetEventPlaylistForEditor'
import {Form} from 'components/DynamicForm/Form/Form'
import {InputContainer} from 'components/DynamicForm/Inputs/shared/InputContainer/InputContainer'
import {DynamicFormConfig} from 'components/DynamicForm/internals/types'
import {z} from 'zod'

import {EventPlaylistEventsInput} from './EventPlaylistEventsInput'
import {EventPlaylistImageUpload} from './EventPlaylistImageUpload'
import {EventPlaylistSortOrderInput} from './EventPlaylistSortOrderInput'

interface EventPlaylistFormProps {
  playlist?: GetEventPlaylistForEditorOutput['playlist']
  onSubmit: (data: CreateEventPlaylistInput) => void
  isSubmitting: boolean
  submitText?: string
}

const eventPlaylistSchema = z.object({
  title: z.string().min(1).max(45),
  // valid slug regex (i.e. "my-slug" or "my-slug-2")
  slug: z.string().regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, {message: 'Invalid slug format, must be lowercase with dashes'}),
  description: z.string().min(1),
  backgroundImage: z.string().min(1),
  mobileBackgroundImage: z.string().min(1).url().optional(),
  eventIds: z.array(z.string()),
  featured: z.boolean(),
  sortOrder: z.array(z.enum(['ticketsSold', 'date', 'totalGmv'])),
})

export const EventPlaylistForm = (props: EventPlaylistFormProps) => {
  const {playlist, onSubmit, isSubmitting, submitText} = props
  const navigate = useNavigate()

  const eventPlaylistFormConfig: DynamicFormConfig<z.infer<typeof eventPlaylistSchema>>[] = [
    {
      key: 'title',
      type: 'text',
      label: 'Title',
      subLabel: 'The title of the playlist (less than 45 characters recommended).',
      placeholder: 'e.g. Miami Music Festival',
    },
    {
      key: 'slug',
      type: 'text',
      label: 'Slug',
      placeholder: 'e.g. miami-music-festival',
      subLabel: 'This will be used in the URL for this playlist (e.g. miami-music-festival).',
      autoCapitalize: 'none',
    },
    {
      key: 'description',
      type: 'text',
      label: 'Description',
      subLabel:
        'A description of the key date/purpose of the playlist (this can be any length: it is not constrained by a character limit as it looks fine expanding to multiple lines).',
      placeholder: 'e.g. The biggest music festival in Miami',
    },
    {
      key: 'sortOrder',
      type: 'custom',
      label: 'Sort Order',
      subLabel: 'The order in which events will be displayed in this playlist is determined by this sort order',
      render: form => {
        const {watch, getFieldState} = form
        const {error} = getFieldState('sortOrder')
        const sortOrder = watch('sortOrder')
        return (
          <div>
            <EventPlaylistSortOrderInput
              value={sortOrder}
              onChange={sortOrder => form.setValue('sortOrder', sortOrder, {shouldDirty: true})}
            />
            {error && <p>{error.message}</p>}
          </div>
        )
      },
    },
    {
      key: 'eventIds',
      type: 'custom',
      label: '',
      render: form => {
        const {watch, getFieldState} = form
        const {error} = getFieldState('eventIds')
        const eventIds = watch('eventIds')
        const sortOrder = watch('sortOrder')
        return (
          <InputContainer error={error?.message}>
            <EventPlaylistEventsInput
              value={eventIds}
              onChange={eventIds => form.setValue('eventIds', eventIds, {shouldDirty: true})}
              sortOrder={sortOrder}
            />
          </InputContainer>
        )
      },
    },
    {
      key: 'featured',
      type: 'switch',
      label: 'Featured',
      subLabel: `This playlist will be listed in the 'Featured Playlists' section on the mobile app.`,
    },
    {
      key: 'backgroundImage',
      type: 'custom',
      label: 'Background Image',
      // TODO: Update this subLabel to be more accurate to the actual dimensions
      subLabel:
        'This will be displayed as the background image for this playlist on the website (greater than or equal to 1920x1080 recommended).',
      render: form => {
        const {watch, getFieldState} = form
        const {error} = getFieldState('backgroundImage')
        const backgroundImage = watch('backgroundImage')
        return (
          <InputContainer error={error?.message}>
            <EventPlaylistImageUpload
              value={backgroundImage}
              onChange={url => form.setValue('backgroundImage', url, {shouldDirty: true})}
            />
          </InputContainer>
        )
      },
    },
    {
      key: 'mobileBackgroundImage',
      type: 'custom',
      label: 'Mobile Background Image (optional)',
      // TODO: Update this subLabel to be more accurate to the actual dimensions
      subLabel:
        'This will be displayed as the background image for this playlist on the mobile app and mobile view of the website (1080x1920 recommended).',
      render: form => {
        const {watch, getFieldState} = form
        const {error} = getFieldState('mobileBackgroundImage')
        const mobileBackgroundImage = watch('mobileBackgroundImage')
        return (
          <InputContainer error={error?.message}>
            <EventPlaylistImageUpload
              value={mobileBackgroundImage}
              onChange={url => form.setValue('mobileBackgroundImage', url, {shouldDirty: true})}
            />
          </InputContainer>
        )
      },
    },
  ]

  return (
    <Form
      defaultValues={{
        sortOrder: ['date'],
        eventIds: playlist?.eventIds ?? [],
        featured: playlist?.featured ?? false,
        ...playlist,
      }}
      inputConfig={eventPlaylistFormConfig}
      schema={eventPlaylistSchema}
      submitText={submitText}
      isSubmitting={isSubmitting}
      onSubmit={playlist => onSubmit(playlist)}
      onCancel={() => navigate('/manage-event-playlists')}
    />
  )
}
