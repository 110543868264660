import React, {PropsWithChildren, useEffect, useState} from 'react'
import {useCookies} from 'react-cookie'

import {ACCESS_FEATURE_FLAGS_HEADER_KEY, NATIVE_OS_HEADER_KEY} from '@posh/types'
import {onlineManager, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {httpLink} from '@trpc/client'

import {baseURL, buildUrl} from '../apis/NavigationHelper'
import {ToastContext, useToast} from '../components/toasts/ToastProvider'
import useSessionContext from '../domains/Auth/SessionContext'
import {trpc} from '../lib/trpc'

/**
 * This component provides a catch-all error handler for trpc hooks and mutations.
 * If a hook or mutation throws an error with a code of 'UNAUTHORIZED', the user is
 * logged out and redirected to the login page. (only if the user is not on the event page or the create event page since they have their own login flows)
 */
const onHookError = (err: any, unauthenticateSession: () => void, showToast: ToastContext['showToast']) => {
  if (err && err.data && err.data.code === 'UNAUTHORIZED') {
    unauthenticateSession()
    if (window.location.pathname[1] !== 'e' && !window.location.pathname.startsWith('/create'))
      window.location.href = `${baseURL}/login`
    else showToast({type: 'error', title: 'Your session has expired', subtitle: 'Please log in and try again'})
  }
}

export function TrpcQueryClientProvider({children}: PropsWithChildren) {
  const {unauthenticateSession} = useSessionContext()
  const {showToast} = useToast()
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            onError: err => onHookError(err, unauthenticateSession, showToast),
          },
          mutations: {
            onError: err => onHookError(err, unauthenticateSession, showToast),
          },
        },
      }),
  )

  const [cookies] = useCookies(['campaignId', 'accessFeatureFlags'])
  const [trpcClient] = useState(() => {
    return trpc.createClient({
      links: [
        httpLink({
          url: buildUrl('/api/web/v2/trpc'),
          headers() {
            const headers: any = {}
            const token = localStorage.getItem('token')
            const campaignId = cookies.campaignId
            if (campaignId) headers['x-campaign-id'] = campaignId
            if (token) headers['X-JWT-Token'] = token
            headers[NATIVE_OS_HEADER_KEY] = 'web'
            headers[ACCESS_FEATURE_FLAGS_HEADER_KEY] = cookies.accessFeatureFlags
            return headers
          },
          fetch: async (url, options): Promise<Response> => {
            const res = await fetch(url, options)
            const refreshToken = res.headers.get('X-Renewed-JWT-Token')

            if (refreshToken) {
              localStorage.setItem('token', refreshToken)
            }

            return res
          },
        }),
      ],
    })
  })

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      <PATCH__ReactQueryOnlineManager />
    </trpc.Provider>
  )
}

/**
 * iOS Simulator incorrectly reports as offline, we must manually override
 * React Query's online status to fix this.
 *
 * With nothing to indicate we're in an iOS Simulator we must rely on our
 * application environment, current online state, and  a network test to
 * ensure we're applying our patch only where needed.
 */
const PATCH__ReactQueryOnlineManager =
  process.env.APP_ENV === 'production'
    ? React.Fragment
    : function PATCH__ReactQueryOnlineManager() {
        const patchable = !onlineManager.isOnline()

        useEffect(() => {
          if (patchable)
            fetch('/', {method: 'OPTIONS'})
              .then(() => onlineManager.setOnline(true))
              .catch(() => console.log('Device is currently offline'))
        }, [patchable])

        return null
      }
