import React from 'react'

import {useMixpanel} from 'apis/MixPanelHandler'
import {PoshLogo} from 'components/PoshLogo'

export const LoginSvg = () => (
  <svg viewBox='0 0 100 100' width='100%' preserveAspectRatio={'none'} className='LoginPage-polygonBackground'>
    <path d='M 0 0 L 100 0 L 100 20 L 0 60 L 0 0' preserveAspectRatio={'none'} style={{fill: '#ffcc00'}} />
    <path d='M 0 58 L 100 18 L 100 22 L 0 62 L 0 0' preserveAspectRatio={'none'} style={{fill: '#e5e5ff90'}} />
  </svg>
)

export const LoginDivLinks = () => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  return (
    <>
      <span style={{fontSize: '16px'}}>
        {"Don't have an account? "}
        <a
          className='LoginPage-form-forgotPassword'
          onClick={() => {
            trackMixpanelEvent('Dont Have An Account Signup-  Main Login')
          }}
          href={'/signup'}>
          Sign Up
        </a>
      </span>
      <a
        style={{fontSize: '16px'}}
        onClick={() => {
          trackMixpanelEvent('Forgot Password-  Main Login')
        }}
        className='LoginPage-form-forgotPassword'
        href={'/reset-password/new'}>
        Forgot Password
      </a>
    </>
  )
}

export const LoginLogo = () => <PoshLogo.Link />

export const LoginFooter = () => (
  <div className='LoginPage-footer'>
    <a href='/'>&copy; POSH</a>
    <span>&mdash;</span>
    <a href='/tos'>Terms</a>
    <span>&mdash;</span>
    <a href='/privacy'>Privacy</a>
  </div>
)
