import React from 'react'

import {ThirdPartyImageApi} from '@posh/types'
import {useDownloadUnsplashImage} from 'apis/Util/useDownloadUnsplashImage'
import {useSearchImages} from 'apis/Util/useSearchImages'
import classNames from 'classnames'
import useDebounce from 'hooks/useDebounce'

interface ImageSelectorProps {
  search: string
  onClick: (image: string) => void
  selectedApi: ThirdPartyImageApi
}

const SEARCH_DEBOUNCE_MS = 500

export const ImageSelector = (props: ImageSelectorProps) => {
  const {search, onClick, selectedApi} = props
  const debouncedSearch = useDebounce(search, SEARCH_DEBOUNCE_MS)

  const {data, isInitialLoading} = useSearchImages({
    selectedApi,
    query: debouncedSearch,
  })
  const {mutateAsync: downloadUnsplashImage} = useDownloadUnsplashImage()

  const handleClick = async (image: string, downloadUrl?: string) => {
    if (selectedApi === 'unsplash' && downloadUrl) {
      await downloadUnsplashImage({downloadUrl})
      onClick(image)
    } else onClick(image)
  }

  return (
    <>
      <div className={classNames('ImageSearchModal-results', {fetchingImages: isInitialLoading})}>
        {data?.images.map((img, i) => (
          <div key={i} className='ImageSearchModal-imageWrapper'>
            <img src={img.image} onClick={() => handleClick(img.image, img.downloadUrl)} />
            {img.photographer && img.photographerUrl && (
              <div className='ImageSearchModal-imageWrapper-photoCreds'>
                <span>by&nbsp;</span>
                <a href={img.photographerUrl} target='_blank' rel='noreferrer'>
                  {img.photographer}
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
      {selectedApi === 'tenor' && (
        <div className='ImageSearchModal-attribution'>
          <a href='https://tenor.com/' target='_blank' rel='noreferrer'>
            <img src='https://www.gstatic.com/tenor/web/attribution/PB_tenor_logo_white_horizontal.png' />
          </a>
        </div>
      )}
    </>
  )
}
