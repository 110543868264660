import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useMixpanel} from 'apis/MixPanelHandler'
import useSessionContext from 'domains/Auth/SessionContext'

import {useGenerateAffiliation} from '../../../apis/Kickback/useGenerateAffiliation'

import './styles.scss'

const KickbackModal = ({
  isOpen,
  onClose,
  eventId,
  kickbackAmount,
  onClickCreateYourLink,
  onClickNavigateToKickbackDashboard,
  onClickNavigateToPurchaseTickets,
  isAttendee,
}: {
  isOpen?: boolean
  onClose: () => void
  eventId: string
  shouldShowButton: boolean
  kickbackAmount: string
  onClickCreateYourLink?: () => void
  onClickNavigateToKickbackDashboard?: () => void
  onClickNavigateToPurchaseTickets?: () => void
  isAttendee: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {userId} = useSessionContext()
  const {mutateAsync: generateAffiliation, isError} = useGenerateAffiliation()
  const navigate = useNavigate()
  const handleCreateKickback = async () => {
    trackMixpanelEvent('Create Kickback Attendee-  Event Page')
    if (isError) return navigate('/dashboard/kickback')
    setErrorMessage('')
    setIsLoading(true)
    if (!userId) {
      setErrorMessage('Please login to setup kickbacks')
      setIsLoading(false)
      return
    }
    await generateAffiliation({userId, eventId})
    setIsLoading(false)
    setIsRedirecting(true)
    setTimeout(() => navigate('/dashboard/kickback'), 1500)
  }

  useEffect(() => {
    if (isError) setIsLoading(false)
  }, [isError])

  const buttonText = () => {
    if (!isAttendee) return 'Purchase Tickets'
    if (isError) return 'Navigate to Kickback Dashboard'
    else if (isLoading) return 'Working our magic...'
    else return '+ Create Your Link'
  }

  const onClickPrimaryButton = () => {
    if (!isAttendee) onClickNavigateToPurchaseTickets?.()
    if (buttonText() == '+ Create Your Link') onClickCreateYourLink?.()
    else if (buttonText() === 'Navigate to Kickback Dashboard') onClickNavigateToKickbackDashboard?.()
    handleCreateKickback()
  }

  return (
    <>
      {isOpen && (
        <div className='KickbackModal'>
          <div className='KickbackModal-M'>
            <div className='KickbackModal-MI'>
              <img src='https://assets.posh.vip/b2/kickback.png' />
              <h3 className='noMargin'>{kickbackAmount}</h3>
              <p className='noMargin'>
                Get cash when friends use your Kickback link to purchase tickets.
                <br />
                {isAttendee
                  ? 'Tap to activate Kickback, share your link, and start getting paid for invites.'
                  : 'Purchase a ticket and share your link and start earning money instantly when they purchase a ticket through your link!'}
              </p>
              <button
                disabled={isLoading}
                className='KickbackBtn'
                onClick={onClickPrimaryButton}
                style={{maxWidth: '300px'}}>
                {buttonText()}
              </button>
              {errorMessage && <p style={{color: 'red', fontWeight: 'lighter'}}>{errorMessage}</p>}
              {isRedirecting && (
                <p className='KickbackModal-MI-redirecting' style={{fontWeight: 'lighter'}}>
                  Redirecting you to the kickback dashboard...
                </p>
              )}
            </div>
            <img src='https://assets.posh.vip/b2/close.svg' className='KickbackModal-x' onClick={() => onClose()} />
          </div>
        </div>
      )}
    </>
  )
}

export default KickbackModal
