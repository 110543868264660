import {setSessionContext} from 'apis/Auth/setSessionContext'
import useSessionContext from 'domains/Auth/SessionContext'
import {RouterInputs, RouterOutput, trpc} from 'lib/trpc'

import {AccountModel} from '../Accounts'

export type ConfirmAndAuthenticateAccountParams = RouterInputs['auth']['confirmAndAuthenticateAccount']
export type ConfirmAndAuthenticateAccountOutput = RouterOutput['auth']['confirmAndAuthenticateAccount']

export function useConfirmAndAuthenticateAccount() {
  const sessionContext = useSessionContext()
  return trpc.auth.confirmAndAuthenticateAccount.useMutation({
    onSuccess: data => {
      const {userId, token, account, expires, accountRoles, groupsWhereAccountIsOwnerMap} = data
      setSessionContext(
        {
          userId,
          token,
          account: account as AccountModel,
          expires,
          accountRoles,
          groupsWhereAccountIsOwnerMap,
        },
        sessionContext,
      )
    },
  })
}
