import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'

import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {useFetchAccountBadges} from 'apis/Accounts/useFetchAccountBadges'
import {useFetchEventGuestlist} from 'apis/Events/useFetchEventGuestlist'
import {useLikeAttendee} from 'apis/Events/useLikeAttendee'
import {useUnlikeAttendee} from 'apis/Events/useUnlikeAttendee'
import {useMixpanel} from 'apis/MixPanelHandler'
import {useIsCurrentUserFollowingAccount} from 'apis/Social/accounts/useListAccountsFollowing'
import {verifiedBadge} from 'components/assets/Icons'
import Button from 'components/form/Button'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import useSessionContext from 'domains/Auth/SessionContext'
import EditProfilePictureModal from 'pages/EventPage/EditProfilePictureModal'
import ProfileBadges from 'pages/Profile/Badges'
import {ProfileFollowButton} from 'pages/Profile/components/ProfileFollowButton'
import ProfileSocials from 'pages/Profile/Socials'

import './styles.scss'

export interface PreviewProfileForModal {
  accountId: string
  profileAvi: string
  instagramVerified: boolean
  poshVerified: boolean
  firstName: string
  lastName: string
  instagram?: string
  twitter?: string
}

interface ProfilePreviewModalProps extends PoshStyledModalProps {
  previewProfile: PreviewProfileForModal
  eventId: string
  isViewingInRSVPEvent?: boolean
  isViewerAttendingEvent?: boolean
  rsvpCheckout: () => void
  onClose: (save?: boolean) => void
}

const ProfilePreviewAvi = ({avi}: {avi: string}) => {
  return (
    <img className='GuestlistProfileModal-avi' src={avi} onError={e => (e.currentTarget.src = DEFAULT_ACCOUNT_AVI)} />
  )
}

const ProfilePreviewModal = ({
  isViewingInRSVPEvent = false,
  isViewerAttendingEvent = true,
  eventId,
  ...props
}: ProfilePreviewModalProps) => {
  const {previewProfile, rsvpCheckout} = props
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const {userId, currentUser} = useSessionContext()
  const currentUserHasAvi = currentUser?.avi || currentUser?.instagram?.avi
  const navigate = useNavigate()
  const viewerCanSeeProfile = isViewerAttendingEvent && !!currentUserHasAvi
  const {data: userWithBadges, isLoading} = useFetchAccountBadges(previewProfile.accountId, viewerCanSeeProfile)
  const {badges, username} = userWithBadges ?? {}
  const {mutate: likeAttendee} = useLikeAttendee()
  const {mutate: unlikeAttendee} = useUnlikeAttendee()
  const isInEventPage = window.location.pathname[1] === 'e' || window.location.pathname[1] === 'f'
  const isViewerProfile = previewProfile.accountId === userId

  const {isCurrentUserFollowingAccount} = useIsCurrentUserFollowingAccount(previewProfile.accountId)

  const {data} = useFetchEventGuestlist(
    {
      eventId,
      viewerId: currentUser?._id,
    },
    {refetchOnMount: false},
  )
  const guestlist = data?.guestlist
  const wasLikedByViewer = useMemo(
    () => guestlist?.find(g => g.attendeeAccountId === previewProfile.accountId)?.likedByViewer,
    [guestlist, previewProfile.accountId],
  )

  const onClickImperativeButton = () => {
    trackMixpanelEvent('Guestlist Purchase/Rsvp Button Action-  Guestlist Click Modal')
    if (isViewingInRSVPEvent) {
      trackMixpanelEvent('Profile Preview Modal Click', {
        whichProfile: previewProfile.accountId,
        where: 'rsvp_event',
        wasInPaywall: true,
        numberOfBadges: badges?.length ?? 0,
      })
      rsvpCheckout()
      props.onClose(true)
    } else {
      trackMixpanelEvent('Profile Preview Modal Click', {
        whichProfile: previewProfile.accountId,
        where: 'purchase_tickets',
        wasInPaywall: true,
        numberOfBadges: badges?.length ?? 0,
      })
      const ticketsSection = document.getElementsByClassName('EventPage-ticketSection')[0]
      ticketsSection.scrollIntoView({behavior: 'smooth'})
      props.onClose()
    }
  }

  const onClickViewProfile = (username: string) => {
    trackMixpanelEvent('Profile Preview Modal Click', {
      whichProfile: previewProfile.accountId,
      where: 'view_profile',
      wasInPaywall: false,
      numberOfBadges: badges?.length ?? 0,
    })
    navigate(`/p/${username}`)
  }

  const onClickLikeButton = () => {
    if (wasLikedByViewer) {
      return unlikeAttendee({eventId, attendeeId: previewProfile.accountId})
    }
    likeAttendee({attendeeId: previewProfile.accountId, eventId})
    trackMixpanelEvent('Like Click', {
      whichGuest: previewProfile.accountId,
      sucessfullyLikedUser: true,
      within: 'profile_preview_modal',
      numberOfBadges: badges?.length ?? 0,
    })
  }

  if (!isViewerAttendingEvent) {
    const promptImperative = isViewingInRSVPEvent ? 'RSVP' : 'Purchase Tickets'
    return (
      <PoshStyledModal {...props}>
        <div className='GuestlistProfileModal'>
          <div className='GuestlistProfileModal-aviWrapper'>
            <ProfilePreviewAvi avi={previewProfile.profileAvi} />
          </div>
          <h4>{`${promptImperative} to interact with ${previewProfile.firstName}!`}</h4>
          <Button onClick={onClickImperativeButton} className='dark round'>
            {promptImperative}
          </Button>
        </div>
      </PoshStyledModal>
    )
  }

  if (isViewerAttendingEvent && !currentUserHasAvi) {
    return (
      <EditProfilePictureModal
        isOpen={isViewerAttendingEvent && !currentUserHasAvi}
        onClose={uploadedPicture => {
          !uploadedPicture && props.onClose()
        }}
      />
    )
  }

  return (
    <PoshStyledModal {...props}>
      <div className='GuestlistProfileModal'>
        <div className='GuestlistProfileModal-aviWrapper'>
          <ProfilePreviewAvi avi={previewProfile.profileAvi} />
          {(previewProfile.instagramVerified || previewProfile.poshVerified) && (
            <img
              className='GuestlistProfileModal-avi-verified'
              src={previewProfile.instagramVerified ? verifiedBadge : 'https://assets.posh.vip/b2/verify.png'}
            />
          )}
        </div>

        <h3 className='noMargin'>{previewProfile.firstName + ' ' + previewProfile.lastName}</h3>

        <ProfileSocials
          instagram={previewProfile.instagram}
          twitter={previewProfile.twitter}
          likedByViewer={wasLikedByViewer}
          onClickLikeButton={onClickLikeButton}
          hideLikeButton={!isInEventPage || isViewerProfile}
          location='profile preview modal'
        />

        {isLoading && <SpinLoader />}
        {badges && badges.length > 0 && <ProfileBadges badges={badges} />}
        {userId && username && (
          <>
            <ProfileFollowButton
              type={'account'}
              isFollowing={isCurrentUserFollowingAccount}
              name={previewProfile.firstName}
              _id={previewProfile.accountId}
              url={username}
            />
            <Button onClick={() => onClickViewProfile(username)} className='dark round'>
              View Profile
            </Button>
          </>
        )}
      </div>
    </PoshStyledModal>
  )
}

export default ProfilePreviewModal
